import {
    request
} from "./network";


// 保存或者修改题号
export function saveOrUpdateTitle(data) {
    return request({
        method: "post",
        url: "title/saveOrUpdateTitle",
        data,
    });
}

// 获取所有考试题号
export function listBySubjectUuid(params) {
    return request({
        method: "get",
        datatype: "json",
        url: "title/listBySubjectUuid",
        params,
    });
}

// 获取考试管理所有考试数据列表的接口
export function getExamData(params) {
    return request({
        method: "get",
        url: "exam/lists",
        params,
    });
}

// 获取所有科目的接口
export function getAllSubjectData(data) {
    return request({
        method: "get",
        url: "/subject/getSubject",
        data,
    });
}

// 根据examId显示当前考试所有科目的接口
export function getExamSubjectData(params) {
    return request({
        method: "post",
        url: "/subject/getAllForExamId",
        params,
    });
}

// 创建考试的接口
export function createExam(params) {
    return request({
        method: "post",
        url: "/exam/create",
        params,
    });
}

// 修改考试的接口
export function updateExam(params) {
    return request({
        method: "post",
        url: "/exam/update",
        params,
    });
}

// 删除考试的接口
export function deleteExam(params) {
    return request({
        method: "get",
        url: "/exam/delete",
        params,
    });
}

// 创建考试科目的接口
export function createSubject(params) {
    return request({
        method: "post",
        url: "/subject/insert",
        params,
    });
}

// 更新考试科目的接口
export function updateSubject(params) {
    return request({
        method: "post",
        url: "/subject/update",
        params,
    });
}

// 删除考试科目的接口
export function deleteSubject(params) {
    return request({
        method: "get",
        url: "/subject/deletes",
        params,
    });
}

// 上传试题
export function uploadQue(data) {
    return request({
        method: "post",
        url: "paperOss/uploadOss",
        data,

    });
}

export function uploadUrlOss(data) {
    return request({
        method: "post",
        url: "/paperOss/uploadUrlOss",
        data,

    });
}

export function uploadBeginAndEndTime(data) {
    return request({
        method: "post",
        url: "paperOss/uploadBeginAndEndTime",
        data,

    });
}

// 开启关闭截图
export function startOrCloseVideo(params) {
    return request({
        method: "get",
        url: "video/openOrCloseZb",
        params,
    });
}