<template>
  <el-container
    class="video-root-box"
    ref="videoRoot"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-aside></el-aside>
    <el-header height="auto">
      <Header :userInfo="userInfo"></Header>
    </el-header>
    <el-main style="position: relative">
      <!-- 导航 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">考试列表</el-breadcrumb-item>
        <el-breadcrumb-item>查看信息</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="box-header">
        <span class="examName">考试名称：{{ examName }}</span>
        <span
          >科目：
          <el-select
            v-model="smallSubjectUuid"
            slot="prepend"
            placeholder="请选择"
            @change="changeSmallSubject"
          >
            <el-option
              v-for="(item, index) in selectSubjectLIst"
              :key="index"
              :value="item.smallSubjectUuid"
              :label="item.smallSubjectName"
            ></el-option>
          </el-select>
          <el-select
                  v-model="groupIdValue"
                  slot="prepend"
                  placeholder="请选择组"
                  @change="changeGroup"
          >
            <el-option
                    v-for="(item, index) in groupList"
                    :key="index"
                    :value="item.groupId"
                    :label="item.groupName"
            ></el-option>
          </el-select>
          <el-input
            placeholder="请输入考生姓名/证件号"
            suffix-icon="el-icon-search"
            v-model="inputSearch"
            @input="onInputSearch"
          >
          </el-input>
        </span>

        <div class="video" v-if="studentList.length > 0">
         <!-- <el-popover
            placement="bottom"
            width="230"
            v-model="popoverVisibleEnd"
          >
            <div class="popover-tree">
              <el-tree
                ref="endTree"
                :data="treeDataEnd"
                show-checkbox
                node-key="id"
                :props="defaultProps"
                :default-checked-keys="defaultCheckedKeys"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span class="status_1" v-if="data.status == 1">面试中</span>
                </span>
              </el-tree>
              <div class="tree-btn">
                <el-button class="cancel" @click="onPopoverCancel(2, 'endTree')"
                  >取消</el-button
                >
                <el-button
                  class="confirm"
                  @click="onPopoverConfirm(2, 'endTree')"
                  >确认</el-button
                >
              </div>
            </div>
            <el-button
              type="primary"
              slot="reference"
              @click="setMsHandle(2, 'endTree')"
              >退出面试</el-button
            >
          </el-popover>
          <el-popover
            placement="bottom"
            width="230"
            v-model="popoverVisibleStart"
          >
            <div class="popover-tree">
              <el-tree
                ref="startTree"
                :data="treeDataStart"
                show-checkbox
                node-key="id"
                :props="defaultProps"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span class="status_1" v-if="data.status == 1">面试中</span>
                </span>
              </el-tree>
              <div class="tree-btn">
                <el-button
                  class="cancel"
                  @click="onPopoverCancel(1, 'startTree')"
                  >取消</el-button
                >
                <el-button
                  class="confirm"
                  @click="onPopoverConfirm(1, 'startTree')"
                  >确认</el-button
                >
              </div>
            </div>
            <el-button
              type="primary"
              slot="reference"
              @click="setMsHandle(1, 'startTree')"
              >开始面试</el-button
            >
          </el-popover>
          <span v-if="showControl == 0 || showControl == 2">
            <el-button type="primary" @click="handleControl"
              >数据监控</el-button
            >
          </span>
          <span v-if="showControl == 1 || showControl == 2">
            <el-button type="primary" @click="handleVideo">视频监控</el-button>
          </span>
          <span v-if="showControl == 0 || showControl == 1">
            <el-button type="primary" @click="handleRecordError"
              >异常记录</el-button
            >
          </span>-->
          <span>全体通知：</span>
          <div><i class="el-icon-chat-dot-round" @click="msgShow(0)"></i></div>
          <div>
            <i
              :class="all_call ? 'ydmaikefengxianxing' : 'ydmaikefeng-jingyin'"
              class="ydfont"
              @click="callAllStudent()"
            ></i>
          </div>
        </div>
      </div>
      <el-container class="container-box">
        <el-aside v-if="drawerDialog">
        <!--切换考生列表与消息列表
          <div class="aside-header">
            <h2>监控列表</h2>
            <div>
              <el-input
                v-model="name"
                placeholder="输入考生姓名"
                prefix-icon="el-icon-search"
                class="myInput"
                @input="searchStudent"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="menu-box">
            <el-radio-group v-model="checkboxGroup" @change="noJtTypeButton">
              <el-radio-button :label="1">所有考生</el-radio-button>
              <el-radio-button :label="2">
                <el-badge
                  v-if="messageUnCount > 0"
                  :value="messageUnCount"
                  class="item"
                >
                  <div class="changeType-text">消息</div>
                </el-badge>
                <div class="changeType-text" v-else>消息</div>
              </el-radio-button>
             <el-radio-button :label="3">
                                <el-badge
                                        v-if="noJtTypeUnCount > 0"
                                        :value="noJtTypeUnCount"
                                        class="item"
                                >
                                    <div class="changeType-text">预警</div>
                                </el-badge>
                                <div class="changeType-text" v-else>预警</div>
                            </el-radio-button>
            </el-radio-group>
            <el-menu
              class="el-menu-aside"
              v-if="checkboxGroup == 1"
              default-active="0"
              v-loading="menuLoading"
              :element-loading-text="menuText"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <el-menu-item
                v-for="(item, index) in searchList"
                :key="index.toString()"
                :index="index.toString()"
              >
                <el-badge
                  :is-dot="item.abnormal == videoSize ? false : true"
                  class="item-badge abnormalList"
                >
                  <el-row>
                    <el-col :span="16" class="menu-zkz">
                      {{ item.name + "_" + item.zkzNum }}
                    </el-col>
                    <el-col :span="8">
                      <div
                        v-for="(itemVideo, indexVideo) in userVideoList"
                        :key="indexVideo"
                      >
                        <el-link
                          @click="
                            showVideoDo(
                              subjectUuid + '_' + item.zkzNum + '_' + itemVideo,
                              item
                            )
                          "
                          type="primary"
                          :disabled="
                            item[
                              subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                            ].is_join == 0
                          "
                          v-text="userVideoListTest[itemVideo]"
                        >
                        </el-link>
                      </div>
                    </el-col>
                  </el-row>
                </el-badge>
              </el-menu-item>
            </el-menu>
            <el-menu
              class="el-menu-aside message-box"
              v-if="checkboxGroup == 2"
              :infinite-scroll-disabled="noMessage ? 'disabled' : ''"
              v-infinite-scroll="loadMessage"
              style="overflow: auto"
              default-active="0"
            >
              <el-menu-item
                v-for="(item, index) in messageList"
                :key="index.toString()"
                class="infinite-list-item"
                :index="index.toString()"
              >
                <el-badge
                  :is-dot="item.isRead == 0 ? true : false"
                  class="item-badge"
                >
                  <el-row>
                    <el-col :span="16" class="menu-zkz">
                      {{ item.userName }}请求举手
                    </el-col>
                    <el-col :span="8">
                      <div>
                        <el-link
                          @click="
                            showVideoSocket(index, item.channelId, item.userid)
                          "
                          type="primary"
                          >查看
                        </el-link>
                      </div>
                      <div>|</div>
                      <div>
                        <el-link
                          @click="showVideoAndCall(index, item.userid)"
                          type="danger"
                          >通话
                        </el-link>
                      </div>
                    </el-col>
                  </el-row>
                </el-badge>
              </el-menu-item>
              <p v-if="loadingMessage">加载中...</p>
              <p v-if="noMessage">没有更多了</p>
            </el-menu>
            <el-menu
              class="el-menu-aside jtType-box"
              v-if="checkboxGroup == 3"
              :infinite-scroll-disabled="noJtType ? 'disabled' : ''"
              v-infinite-scroll="loadingJtType"
              style="overflow: auto"
              default-active="0"
            >
              <el-menu-item
                v-for="(item, index) in countJtTypesList"
                :key="index.toString()"
                class="infinite-list-item"
                :index="index.toString()"
              >
                <el-badge
                  :is-dot="item.status == 0 ? false : true"
                  class="item-badge"
                >
                  <el-row>
                    <el-col :span="16" class="menu-zkz">
                      {{ item.name }}-{{ item.zkzNum }}
                    </el-col>
                    <el-col :span="8">
                      <div>
                        <el-link
                          @click="showStudentDialogShow(item.zkzNum)"
                          type="primary"
                          >查看
                        </el-link>
                      </div>
                    </el-col>
                    <el-col :span="16" class="menu-zkz">
                      <span v-for="(item2, index2) in item.type">{{
                        countJtTypeStatus(item2)
                      }}</span>
                    </el-col>
                    <el-col :span="8">
                      <div class="count">{{ item.num }}次</div>
                    </el-col>
                  </el-row>
                </el-badge>
              </el-menu-item>
              <p v-if="loadingJtType">加载中...</p>
              <p v-if="noJtType">没有更多了</p>
            </el-menu>
            <div
              class="no-data"
              v-if="checkboxGroup == 1 && searchList.length == 0"
            >
              暂无考生
            </div>
            <div
              class="no-data"
              v-if="checkboxGroup == 2 && messageList.length == 0"
            >
              暂无消息
            </div>
          </div>-->
        </el-aside>
        <!-- 视频监控 -->
        <el-main
          v-if="showControl == 0"
          v-loading="topLoading"
          :element-loading-text="topText"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.8)"
        >
          <div class="video-user-box" v-if="showStudentList.length > 0">
            <div
              v-for="(item, index) in showStudentList"
              :key="index"
              :class="`${'video-size' + videoSize} ${
                item.is_check == 0 ? 'user-box' : 'user-boxerror'
              }`"
              :ref="item.zkzNum"
            >
              <div class="user-check_close" v-if="item.is_check == 1">
                <i
                  @click="hideStudentDialogShow(item.zkzNum)"
                  class="el-icon-close"
                ></i>
              </div>
              <div
                class="user-box1"
                v-for="(itemVideo, indexVideo) in userVideoList"
                :key="indexVideo"
                :class="showVideo ? '' : 'showVideo'"
              >
                <!--<img
                                        class="thussat_logo"
                                        src="../../../assets/image/logo.svg"
                                        alt=""
                                />
                                <div class="status_icon">
                                    <i
                                            v-if="item.testStatus == 0"
                                            class="el-icon-circle-close"
                                            style="font-size: 25px; color: red"
                                            @click="testStatusChange(item)"
                                    ></i>
                                    <i
                                            v-else
                                            class="el-icon-circle-check"
                                            style="font-size: 25px; color: green"
                                    ></i>
                                </div>-->
                <div class="video_sign" v-if="itemVideo === 'front'">
                  <div v-if="item.status == 0">候考{{ item.sortNum }}</div>
                  <div v-else-if="item.status == 1">面试{{ item.sortNum }}</div>
                  <div v-else-if="item.status == 2">
                    面试结束{{ item.sortNum }}
                  </div>
                </div>
                <div
                  v-if="item.faceStatus == '2' && itemVideo === 'front'"
                  class="isFacePass"
                >
                  <div>未通过人脸识别</div>
                </div>
                <video
                  :ref="
                    'video_' + subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                  "
                  :key="
                    'video_' + subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                  "
                  autoplay
                  playsinline
                  v-show="showVideoModel(itemVideo, item)"
                ></video>
                <div
                  class="video_action"
                  v-if="
                    item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                      .is_join == 1
                  "
                >
                  <template v-if="!showVideo && itemVideo !== 'screen'">
                    <i class="el-icon-top" @click="toTop(item)"></i>
                    <i class="el-icon-bottom" @click="toBottom(item)"></i>
                    <i
                      class="el-icon-chat-dot-round"
                      @click="msgShow(1, item, itemVideo)"
                    ></i>
                  </template>
                  <template
                    v-if="
                      item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                        .is_video == 1 && itemVideo != 'screen'
                    "
                  >
                    <i
                      :class="
                        item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                          .is_audio == 1
                          ? 'yd004laba-3'
                          : 'yd004laba-4'
                      "
                      class="ydfont"
                      @click="
                        subscribeAudio(
                          subjectUuid + '_' + item.zkzNum + '_' + itemVideo,
                          item
                        )
                      "
                    ></i>
                    <i
                      :class="
                        item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                          .is_call == 1
                          ? 'ydmaikefengxianxing'
                          : 'ydmaikefeng-jingyin'
                      "
                      @click="
                        callOneStudent(
                          subjectUuid + '_' + item.zkzNum + '_' + itemVideo,
                          item
                        )
                      "
                      v-if="itemVideo !== 'screen'"
                      class="ydfont"
                    ></i>
                  </template>
                  <template>
                    <i
                      :class="showVideo ? 'ydicon--1' : 'ydicon--'"
                      class="ydfont"
                      @click="
                        showTopVideo(
                          subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                        )
                      "
                    ></i>
                  </template>
                </div>
                <div
                  class="video_loading"
                  v-show="!showVideo"
                  :element-loading-text="
                    item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                      .is_join == 1
                      ? '加载视频中'
                      : '未加入监控'
                  "
                  element-loading-spinner="el-icon-loading"
                  v-loading="
                    item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                      .is_video == 0
                  "
                ></div>
              </div>

              <div class="user-info flex">
                <div class="user_text">
                  <!--<span>证件号：</span>
                  <span v-text="item.zkzNum"></span>-->
                  <span>考生姓名：</span>
                  <span v-text="item.name"></span>
                </div>
                <!--<div
                  class="user_text"
                  v-if="isChangeStatus == 0"
                  style="font-weight: 600"
                >
                  <div
                    class="text_btn"
                    v-if="item.status == 0 || item.status == 2"
                  >
                    <div @click="beginInterviewOne(item)">开始面试</div>
                  </div>
                  <div
                    class="text_btn"
                    style="color: red"
                    v-if="item.status == 1"
                  >
                    <div @click="endInterviewOne(item)">退出面试</div>
                  </div>
                  <el-popover
                    class="text_btn_more"
                    placement="bottom"
                    width="100"
                    trigger="click"
                    @show="showChannel(item)"
                  >
                  <div>
                                            <div>开考放行：</div>
                                            <div>
                                                <el-radio-group
                                                        v-model="radioTest"
                                                        @change="(val) => changeHandlerTest(val, item)"
                                                >
                                                    <el-radio :label="1">开</el-radio>
                                                    <el-radio :label="0">关</el-radio>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div>
                                            <div>填码通道：</div>
                                            <div>
                                                <el-radio-group
                                                        v-model="radioCode"
                                                        @change="(val) => changeHandlerCode(val, item)"
                                                >
                                                    <el-radio :label="1">开</el-radio>
                                                    <el-radio :label="0">关</el-radio>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div>
                                            <div>交卷通道：</div>
                                            <div>
                                                <el-radio-group
                                                        v-model="radioPaper"
                                                        @change="(val) => changeHandlerPaper(val, item)"
                                                >
                                                    <el-radio :label="1">开</el-radio>
                                                    <el-radio :label="0">关</el-radio>
                                                </el-radio-group>
                                            </div>
                                        </div>
                    <div style="margin-top: 10px">
                      <el-button
                        style="color: #fff"
                        size="mini"
                        @click="errorDialog(item)"
                        >记录异常
                      </el-button>
                    </div>
                    <div style="margin-top: 10px">
                      <el-button
                        style="color: #fff"
                        size="mini"
                        @click="helpDialog(item)"
                        >请求协助
                      </el-button>
                    </div>
                    <div style="margin-top: 10px">
                      <el-button
                        style="color: #fff"
                        size="mini"
                        @click="closeMonitor(item)"
                        >关闭监控
                      </el-button>
                    </div>
                    <div style="margin-top: 10px">
                      <el-button
                        style="color: #fff"
                        size="mini"
                        @click="toPhotoList(item, item.zkzNum, item.name)"
                        >查看证件照
                      </el-button>
                    </div>
                    <div class="text_btn2" slot="reference">
                      <div>更多>></div>
                    </div>
                  </el-popover>

                 <el-popover
                                            class="text_btn_view"
                                            placement="bottom"
                                            width="120"
                                            trigger="click"
                                            @show="onShowPopover(item)"
                                    >
                                        <div>
                                            <div>
                                                <el-radio-group v-model="item.statusSj"
                                                                @change="(val) => viewQuesHandle(item, val)"
                                                >
                                                    <el-radio :label="0" style="margin: 0 0 5px 0;">关闭试题</el-radio>
                                                    <el-radio :label="1" style="margin: 0 0 5px 0;">开启试题</el-radio>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div class="text_btn2" slot="reference">
                                            <div v-if="item.statusSj==0" style="color: red;">已关闭试题查看</div>
                                            <div v-if="item.statusSj==1">已开启试题查看</div>
                                        </div>
                                    </el-popover>
                                    <div class="text_btn">
                                        <div v-if="item.testStatus == 0"
                                             @click="testStatusChange(item, index)"
                                        >
                                            完成检查
                                        </div>
                                        <div v-else style="color: gray">完成检查</div>
                                    </div>
                                    <div class="text_btn1">
                                        <div @click="toImgList(item, item.zkzNum, item.name)">
                                            查看答案
                                        </div>
                                    </div>

                                    <el-popover
                                            class="text_btn_view2"
                                            placement="bottom"
                                            width="180"
                                            trigger="click"
                                            @show="onShowPopover(item)"
                                    >
                                        <div>
                                            <div>
                                                <el-radio-group v-model="item.delayOpen"
                                                                @change="(val) => viewQuesDelHandle(item, val)"
                                                >
                                                    <el-radio :label="0" style="margin: 0 0 5px 0;">关闭迟到开启试题</el-radio>
                                                    <el-radio :label="1" style="margin: 0 0 5px 0;">开启迟到开启试题</el-radio>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div class="text_btn2" slot="reference">
                                            <div v-if="item.delayOpen==0" style="color: red;">已关闭迟到开启试题</div>
                                            <div v-if="item.delayOpen==1">已开启迟到开启试题</div>
                                        </div>
                                    </el-popover>
                </div>
                <div v-else class="user_text" style="font-weight: 600">
                  <div class="text_btn1">
                    <el-button @click="toErrorList(item.zkzNum, item.name)">
                      查看预警
                    </el-button>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
          <el-row>
            <el-col :span="4" v-if="false">
              <el-select
                v-model="setTimeoutPageTime"
                @change="setTimeoutPage"
                placeholder="请选择"
              >
                <el-option-group
                  v-for="group in setTimeoutPageOptions"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </el-col>
            <el-col :span="24">
              <!-- 分页器 -->
              <el-pagination
                v-if="total"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[4, 6, 8, 12]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-count="totalPage"
              >
              </el-pagination>
            </el-col>
          </el-row>
          <div class="no-data" v-if="showStudentList.length == 0">暂无考生</div>
        </el-main>

        <!--  数据监控 -->
        <el-main v-if="showControl == 1">
          <div class="text item">
            <div class="table-box">
              <el-row type="flex" class="row_bg" justify="space-between">
                <el-col :lg="5" :xl="5">
                  <el-input
                    v-model="queryParamsData.condition"
                    placeholder="输入考生姓名/证件号"
                    prefix-icon="el-icon-search"
                    clearable
                    @input="getStaffPageLists()"
                  ></el-input>
                </el-col>
                <el-col :lg="9" :xl="5">
                  <div class="control_num">
                    <span>进入监控人数：{{ EnterNum }}人</span>
                    <span>未进入监控人数：{{ notEnterNum }}人</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-card class="box-card mm">
            <div class="table-box">
              <el-table
                :data="dataControlList"
                style="width: 100%"
                height="auto"
                @filter-change="filterChange"
                stripe
              >
                <el-table-column align="center" prop="name" label="考生姓名">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="
                        showStudentDialogShow(scope.row.zkzNum, 0)
                      "
                      type="text"
                      v-text="scope.row.name"
                    ></el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="zkzNum"
                  label="证件号"
                  min-width="120"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="iphone"
                  label="紧急联系人手机"
                  min-width="120"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="stuPhone"
                  label="考生手机号"
                  min-width="120"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="faceStatus;"
                  label="人脸识别状态"
                  min-width="140"
                  :filter-multiple="false"
                  :filters="[
                    { text: '未识别', value: '0' },
                    { text: '已识别', value: '1' },
                    { text: '识别未通过', value: '2' },
                  ]"
                  filter-placement="bottom-end"
                  column-key="faceStatus"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.faceStatus === 1"
                      style="color: #67c23a"
                      >已识别</span
                    >
                    <span
                      v-else-if="scope.row.faceStatus === 0"
                      style="color: #f56c6c"
                      >未识别</span
                    >
                    <span
                      v-else-if="scope.row.faceStatus === 2"
                      style="color: #f56c6c"
                      >识别未通过</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="monitorStatus;"
                  label="在线监控数"
                  min-width="120"
                  :filter-multiple="false"
                  :filters="[
                    { text: '0', value: '0' },
                    { text: '1', value: '1' },
                    { text: '2', value: '2' },
                  ]"
                  filter-placement="bottom-end"
                  column-key="monitorStatus"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.monitorStatus === 0"
                      style="color: #f56c6c"
                      >0</span
                    >
                    <span
                      v-else-if="scope.row.monitorStatus === 1"
                      style="color: #67c23a"
                      >1</span
                    >
                    <span
                      v-else-if="scope.row.monitorStatus === 2"
                      style="color: #67c23a"
                      >2</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="startExamStatus;"
                  label="开考状态"
                  min-width="100"
                  :filter-multiple="false"
                  :filters="[
                    { text: '未开考', value: '0' },
                    { text: '已开考', value: '1' },
                  ]"
                  filter-placement="bottom-end"
                  column-key="startExamStatus"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.startExamStatus === 1"
                      style="color: #67c23a"
                      >已开考</span
                    >
                    <span
                      v-else-if="scope.row.startExamStatus === 0"
                      style="color: #f56c6c"
                      >未开考</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="pictureStatus;"
                  label="图片提交状态"
                  min-width="150"
                  :filter-multiple="false"
                  :filters="[
                    { text: '未提交', value: '0' },
                    { text: '已提交', value: '1' },
                  ]"
                  filter-placement="bottom-end"
                  column-key="pictureStatus"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.pictureStatus === 1"
                      style="color: #67c23a"
                      >已提交</span
                    >
                    <span
                      v-else-if="scope.row.pictureStatus === 0"
                      style="color: #f56c6c"
                      >未提交</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="handPaperStatus;"
                  label="交卷状态"
                  min-width="100"
                  :filter-multiple="false"
                  :filters="[
                    { text: '未交卷', value: '0' },
                    { text: '已交卷', value: '1' },
                  ]"
                  filter-placement="bottom-end"
                  column-key="handPaperStatus"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.handPaperStatus === 1"
                      style="color: #67c23a"
                      >已交卷</span
                    >
                    <span
                      v-else-if="scope.row.handPaperStatus === 0"
                      style="color: #f56c6c"
                      >未交卷</span
                    >
                  </template>
                </el-table-column>

                <!-- 是否缺考 -->
                <el-table-column
                  align="center"
                  prop="lackExamStatus;"
                  label="是否缺考"
                  min-width="100"
                  :filter-multiple="false"
                  :filters="[
                    { text: '未缺考', value: '0' },
                    { text: '缺考', value: '1' },
                  ]"
                  filter-placement="bottom-end"
                  column-key="lackExamStatus"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.lackExamStatus === 1"
                      style="color: #f56c6c"
                      >缺考</span
                    >
                    <span
                      v-else-if="scope.row.lackExamStatus === 0"
                      style="color: #67c23a"
                      >未缺考</span
                    >
                  </template>
                </el-table-column>
                <!-- 考试结束时间 -->
                <el-table-column
                  align="center"
                  prop="finalEndTime"
                  label="考试结束时间"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.additionalStatus === 1"
                      style="color: #f56c6c"
                      >{{ scope.row.finalEndTime }}</span
                    >
                    <span
                      v-else-if="scope.row.additionalStatus === 0"
                      style="color: #67c23a"
                    >
                      {{ scope.row.finalEndTime }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  label="操作"
                  align="center"
                  min-width="300px"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-show="scope.row.lackExamStatus == 0"
                      style="background-color: #8b2d87"
                      size="mini"
                      @click="signMissexam(scope.row)"
                    >
                      标记缺考
                    </el-button>
                    <el-button
                      v-show="scope.row.lackExamStatus == 1"
                      style="background-color: #8b2d87"
                      size="mini"
                      @click="signMissexam(scope.row)"
                    >
                      取消缺考
                    </el-button>
                    <el-button
                      style="background-color: #da8309"
                      size="mini"
                      type="danger"
                      @click="addTimeDialog(scope.row)"
                    >
                      补时
                    </el-button>
                    <el-button
                      style="background-color: green"
                      size="mini"
                      type="danger"
                      @click="errorDialog(scope.row)"
                    >
                      记录异常
                    </el-button>
                    <el-button
                      style="background-color: green"
                      size="mini"
                      type="danger"
                      @click="helpDialog(scope.row)"
                    >
                      请求协助
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页器 -->
            <el-pagination
              v-if="totalNum"
              background
              @size-change="handleDataSize"
              @current-change="handleDataCurrente"
              :current-page="queryParamsData.pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="queryParamsData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalNum"
            >
            </el-pagination>
            <!-- </div> -->
          </el-card>
        </el-main>

        <!-- 异常记录 -->
        <el-main v-if="showControl == 2" v-loading="errorLoading">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="记录异常" name="first">
              <el-card class="box-card mm">
                <el-table
                  :data="ErrorEventList"
                  style="width: 100%"
                  height="auto"
                  stripe
                >
                  <el-table-column
                    align="center"
                    prop="id"
                    label="事件ID"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="subjectName"
                    label="科目"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="studentName"
                    label="考生姓名"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="zkzNum"
                    label="证件号"
                    min-width="120"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="studentPhone"
                    label="考生手机号"
                    min-width="120"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="guardianPhone"
                    label="紧急联系人手机"
                    min-width="120"
                  ></el-table-column>

                  <el-table-column
                    align="center"
                    prop="categoryType"
                    label="事件类型"
                    min-width="140"
                  >
                  </el-table-column>
                  <!-- 事件描述 -->
                  <el-table-column
                    align="center"
                    prop="categoryDescribe"
                    label="事件描述"
                    min-width="140"
                  >
                  </el-table-column>
                  <!-- 创建时间 -->
                  <el-table-column
                    align="center"
                    prop="createTime"
                    label="创建时间"
                    min-width="120"
                    :formatter="createTimeFormatter"
                  >
                  </el-table-column>
                </el-table>

                <!-- 分页器 -->
                <el-pagination
                  v-if="ErrorEventTotal"
                  background
                  @size-change="handleErrorEventSize"
                  @current-change="handleErrorEventCurrent"
                  :current-page="ErrorEventPageSize"
                  :page-sizes="[10, 15, 20]"
                  :page-size="ErrorEventPageNum"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="ErrorEventTotal"
                >
                </el-pagination>
                <!-- </div> -->
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="请求协助" name="second">
              <el-card class="box-card mm">
                <el-table
                  :data="HelpEventList"
                  style="width: 100%"
                  height="auto"
                  stripe
                >
                  <el-table-column
                    align="center"
                    prop="subjectName"
                    label="科目"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="studentName"
                    label="考生姓名"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="zkzNum"
                    label="证件号"
                    min-width="120"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="guardianPhone"
                    label="紧急联系人手机"
                    min-width="120"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="studentPhone"
                    label="考生手机号"
                    min-width="120"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="categoryType"
                    label="事件类型"
                    min-width="140"
                  >
                  </el-table-column>
                  <!-- 事件描述 -->
                  <el-table-column
                    align="center"
                    prop="categoryDescribe"
                    label="事件描述"
                    min-width="140"
                  >
                  </el-table-column>
                  <!-- 创建时间 -->
                  <el-table-column
                    align="center"
                    prop="createTime"
                    label="创建时间"
                    min-width="120"
                    :formatter="createTimeFormatter"
                  >
                  </el-table-column>
                  <!-- 处理人 -->
                  <el-table-column
                    align="center"
                    prop="solverName"
                    label="处理人"
                    min-width="120"
                  ></el-table-column>
                  <!-- 处理人联系方式 -->
                  <el-table-column
                    align="center"
                    prop="solverPhone"
                    label="处理人联系方式"
                    min-width="120"
                  ></el-table-column>
                  <!-- 处理状态 -->
                  <el-table-column
                    align="center"
                    prop="eventStatus"
                    label="处理状态"
                    min-width="100"
                  >
                  </el-table-column>
                  <!-- 所属组别 -->
                  <el-table-column
                    align="center"
                    prop="solverGroup"
                    label="所属组别"
                    min-width="150"
                  >
                  </el-table-column>

                  <el-table-column
                    label="操作"
                    align="center"
                    min-width="200px"
                    fixed="right"
                  >
                    <template slot-scope="scope">
                      <el-button
                        style="background-color: #8b2d87"
                        size="mini"
                        @click="showStudentDialogShow(scope.row.zkzNum, 0)"
                        >通话
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- 分页器 -->
                <el-pagination
                  v-if="HelpEventTotal"
                  background
                  @size-change="handleHelpEventSize"
                  @current-change="handleHelpEventCurrent"
                  :current-page="HelpEventPageNum"
                  :page-sizes="[10, 15, 20]"
                  :page-size="HelpEventPageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="HelpEventTotal"
                >
                </el-pagination>
                <!-- </div> -->
              </el-card>
            </el-tab-pane>
          </el-tabs>
        </el-main>
        <div
          v-show="top_video"
          class="top_video"
          :class="top_video ? 'showVideo' : ''"
        >
          <video
            :ref="'top_video_' + userId"
            :key="'top_video_' + userId"
            autoplay
            playsinline
          ></video>
          <div class="video_action" v-if="student[userId].is_join == 1">
            <i
              :class="
                student[userId].is_audio == 1 ? 'yd004laba-3' : 'yd004laba-4'
              "
              class="ydfont"
              @click="subscribeAudio(userId, student)"
            ></i>
            <i
              :class="
                student[userId].is_call == 1
                  ? 'ydmaikefengxianxing'
                  : 'ydmaikefeng-jingyin'
              "
              class="ydfont"
              @click="callOneStudent(userId, student)"
            ></i>
            <i class="ydfont ydicon--1" @click="hideTopVideo()"></i>
          </div>
          <div class="video_time">10:11</div>
        </div>

        <div class="video-user-dialog" v-if="showStudentDialog">
          <div
            :class="`${'video-size' + videoSize} ${
              showStudent.is_check == 0 ? 'user-box' : 'user-boxerror'
            }`"
            :ref="showStudent.zkzNum"
          >
            <div class="user-check_close" v-if="showStudent.is_check == 1">
              <i
                @click="hideStudentDialogShow(showStudent.zkzNum)"
                class="el-icon-close"
              ></i>
            </div>
            <div
              class="user-box1"
              v-for="(itemVideo, indexVideo) in userVideoList"
              :key="indexVideo"
              :class="showVideo ? '' : 'showVideo'"
            >
              <!-- <img class="thussat_logo"
                                 src="../../../assets/image/logo.svg"
                                 alt=""
                            />
                            <div class="status_icon">
                              <i
                                v-if="showStudent.testStatus == 0"
                                class="el-icon-circle-close"
                                style="font-size: 25px; color: red"
                                @click="testStatusChange(showStudent)"
                              ></i>
                              <i
                                v-else
                                class="el-icon-circle-check"
                                style="font-size: 25px; color: green"
                              ></i>
                            </div> -->
              <div
                v-if="showStudent.faceStatus == '2' && itemVideo === 'front'"
                class="isFacePass"
              >
                <div>未通过人脸识别</div>
              </div>

              <video
                :ref="
                  'video_' +
                  subjectUuid +
                  '_' +
                  showStudent.zkzNum +
                  '_' +
                  itemVideo
                "
                :key="
                  'video_' +
                  subjectUuid +
                  '_' +
                  showStudent.zkzNum +
                  '_' +
                  itemVideo
                "
                autoplay
                playsinline
              ></video>
              <div
                class="video_action"
                v-if="
                  showStudent[
                    subjectUuid + '_' + showStudent.zkzNum + '_' + itemVideo
                  ].is_join == 1
                "
              >
                <template v-if="!showVideo && itemVideo !== 'screen'">
                  <i
                    class="el-icon-top"
                    @click="toTop(showStudent)"
                    v-if="!showVideo"
                  ></i>
                  <i
                    class="el-icon-chat-dot-round"
                    @click="msgShow(1, showStudent, itemVideo)"
                    v-if="!showVideo"
                  ></i>
                </template>

                <template
                  v-if="
                    showStudent[
                      subjectUuid + '_' + showStudent.zkzNum + '_' + itemVideo
                    ].is_video == 1 && itemVideo !== 'screen'
                  "
                >
                  <i
                    :class="
                      showStudent[
                        subjectUuid + '_' + showStudent.zkzNum + '_' + itemVideo
                      ].is_audio == 1
                        ? 'yd004laba-3'
                        : 'yd004laba-4'
                    "
                    class="ydfont"
                    @click="
                      subscribeAudio(
                        subjectUuid +
                          '_' +
                          showStudent.zkzNum +
                          '_' +
                          itemVideo,
                        showStudent
                      )
                    "
                  ></i>
                  <i
                    :class="
                      showStudent[
                        subjectUuid + '_' + showStudent.zkzNum + '_' + itemVideo
                      ].is_call == 1
                        ? 'ydmaikefengxianxing'
                        : 'ydmaikefeng-jingyin'
                    "
                    @click="
                      callOneStudent(
                        subjectUuid +
                          '_' +
                          showStudent.zkzNum +
                          '_' +
                          itemVideo,
                        showStudent
                      )
                    "
                    class="ydfont"
                  ></i>
                </template>

                <template>
                  <template>
                    <i
                      :class="showVideo ? 'ydicon--1' : 'ydicon--'"
                      class="ydfont"
                      @click="
                        showTopVideo(
                          subjectUuid +
                            '_' +
                            showStudent.zkzNum +
                            '_' +
                            itemVideo
                        )
                      "
                    ></i>
                  </template>
                </template>
              </div>
              <div
                class="video_loading"
                v-show="!showVideo"
                :element-loading-text="
                  showStudent[
                    subjectUuid + '_' + showStudent.zkzNum + '_' + itemVideo
                  ].is_join == 1
                    ? '加载视频中'
                    : '未加入监控'
                "
                element-loading-spinner="el-icon-loading"
                v-loading="
                  showStudent[
                    subjectUuid + '_' + showStudent.zkzNum + '_' + itemVideo
                  ].is_video == 0
                "
              ></div>
            </div>
            <div class="user-info flex">
              <div class="user_text">
                <span>考生姓名：</span>
                <span v-text="showStudent.name"></span>
              </div>
              <div
                class="user_text"
                v-if="isChangeStatus == 0"
                style="font-weight: 600"
              >
                <div class="text_btn">
                  <div
                    v-if="showStudent.testStatus == 0"
                    @click="testStatusChange(showStudent)"
                  >
                    完成检查
                  </div>
                  <div v-else style="color: gray">完成检查</div>
                </div>
                <!-- <div class="text_btn">
                                <div @click="changeStatus(item.zkzNum)">查看预警</div>
                                </div>
                                <div class="text_btn1">
                                    <div @click="toImgList(showStudent, showStudent.zkzNum, showStudent.name)">
                                        查看图片
                                    </div>
                                </div>
                                <el-popover
                                        placement="bottom"
                                        width="100"
                                        trigger="click"
                                        @show="showChannel(showStudent)"
                                >
                                    <div>
                                        <div>填码通道：</div>
                                        <div>
                                            <el-radio-group
                                                    v-model="radioCode"
                                                    @change="(val) => changeHandlerCode(val, showStudent)"
                                            >
                                                <el-radio :label="1">开</el-radio>
                                                <el-radio disabled :label="0">关</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div>
                                        <div>交卷通道：</div>
                                        <div>
                                            <el-radio-group
                                                    v-model="radioPaper"
                                                    @change="(val) => changeHandlerPaper(val, showStudent)"
                                            >
                                                <el-radio :label="1">开</el-radio>
                                                <el-radio disabled :label="0">关</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div @click="closeMonitor(showStudent)">关闭监控</div>

                                    <div class="text_btn2" slot="reference">
                                        <div>更多>></div>
                                    </div>
                                </el-popover>-->
              </div>
              <div v-else class="user_text" style="font-weight: 600">
                <div class="text_btn1">
                  <el-button
                    @click="toErrorList(showStudent.zkzNum, showStudent.name)"
                  >
                    查看预警
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-container>

      <el-drawer
        class="monitor_box_drawer"
        ref="drawerDialog"
        :visible.sync="drawerDialog"
        direction="ltr"
        :with-header="false"
        :modal="false"
        :append-to-body="false"
        :modal-append-to-body="false"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :destroy-on-close="false"
        :before-close="handleClose"
      >
        <div class="monitor_box-container">
          <div
            class="monitor_area"
            v-for="(item, index) in this.otherJudges"
            :key="index"
          >
            <div class="monitor_box">
              <video
                :ref="'monitor_video_' + index"
                autoplay
                playsinline
              ></video>
              <div class="video_action" v-if="item.is_join == 1">
                <i class="el-icon-chat-dot-round" @click="msgShow(2, item)"></i>
                <i
                  :class="item.is_audio == 1 ? 'yd004laba-3' : 'yd004laba-4'"
                  class="ydfont"
                  @click="subscribePWTAudio(index)"
                ></i>
                <i
                  :class="
                    item.is_call == 1
                      ? 'ydmaikefengxianxing'
                      : 'ydmaikefeng-jingyin'
                  "
                  @click="callOnePWT(0, index)"
                  class="ydfont"
                ></i>
              </div>
              <div
                class="video_loading"
                :element-loading-text="
                  item.is_join == 1 ? '评委视频加载中' : '评委未加入监控'
                "
                element-loading-spinner="el-icon-loading"
                v-loading="item.is_video == 0"
              ></div>
            </div>
            <div class="monitor_area_box">
              <div class="monitor_area_name">{{ item.teacherName }}</div>
            </div>
          </div>
        </div>
        <i class="el-icon-d-arrow-left" @click="handleArrowClose"></i>
      </el-drawer>
      <button class="open-drawer" @click="handleArrowOpen">
        评委视频
        <i class="el-icon-s-unfold"></i>
      </button>
    </el-main>
    <!-- 查看上传图片 -->
    <imgListDialog
      :dialogVisibleImg="dialogVisibleImg"
      :zkzNums="zkzNums"
      :names="names"
      :subjectUuids="subjectUuid"
      @closeImgListDialog="closeImgListDialog"
    />

    <!-- 查看证件照 -->
    <photoListDialog
      :dialogVisibleFu="dialogVisiblePhoto"
      :zkzNums="zkzNums"
      :names="names"
      :subjectUuids="subjectUuid"
      @closeDialog="closePhotoListDialog"
    />
    <div class="checkBg" v-if="is_check"></div>

    <!-- 居家考消息 弹框 -->
    <msgDialog
      :dialogVisibleFu="dialogVisibleFu"
      :allSend="all_send"
      :userKey="userKey"
      @closeDialog="closeDialog"
    />
    <!-- 单个预警截图 -->
    <el-dialog
      width="750px"
      title="预警截图"
      :visible.sync="dialogVisibleError"
      @open="openErroImgDialog"
      @close="closeErrorImgDialog"
    >
      <el-table border :data="errorImagesList">
        <el-table-column
          prop="name"
          label="姓名"
          width="100px"
        ></el-table-column>
        <el-table-column prop="zkzNum" label="准考证"></el-table-column>
        <el-table-column prop="type" label="预警类型">
          <template slot-scope="scope">
            <el-tag
              v-for="(item, index) in typeFormat(scope.row)"
              :key="index"
              type="success"
              style="margin: 0 5px 5px 0"
              >{{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="imagesTime" label="截图时间"></el-table-column>
        <el-table-column prop="imageUrl" label="预警截图">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.imageUrl"
              :fit="fit"
              :preview-src-list="[scope.row.imageUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        v-if="errorimgTotal"
        background
        @size-change="handleErrImgSize"
        @current-change="handleErrImgCurrente"
        :current-page="queryParamsError.pageNum"
        :page-sizes="[4, 6, 8, 10]"
        :page-size="queryParamsError.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="errorimgTotal"
      >
      </el-pagination>
    </el-dialog>
    <!-- 查看所有预警table -->
    <el-dialog
      width="720px"
      :title="'科目: ' + subjectName"
      :visible.sync="dialogTableVisible"
      @open="openImgDialog"
      @close="closeImgDialog"
    >
      <el-form
        :model="queryParams"
        ref="queryForm"
        :inline="true"
        label-width="100px"
      >
        <el-form-item label="准考证号：" prop="zkzNum">
          <el-input
            v-model="queryParams.zkzNum"
            placeholder="请输入准考证号"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item label="预警类型" prop="type">
          <el-select
            v-model="queryParams.type"
            placeholder="请选择预警类型"
            size="small"
            clearable
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间：">
          <el-date-picker
            v-model="valuePicker"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            size="small"
            clearable
            @change="changePicker"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="queryHandle"
            >搜索
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh"
            size="mini"
            @click="resetHandle"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="imagesList">
        <el-table-column prop="zkzNum" label="准考证"></el-table-column>
        <el-table-column prop="type" label="预警类型">
          <template slot-scope="scope">
            <el-tag
              v-for="(item, index) in typeFormat(scope.row)"
              :key="index"
              type="success"
              style="margin: 0 5px 5px 0"
              >{{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="imagesTime" label="截图时间"></el-table-column>
        <el-table-column prop="imageUrl" label="预警截图">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.imageUrl"
              :preview-src-list="[scope.row.imageUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        v-if="imgTotal"
        background
        @size-change="handleImgSizeChange"
        @current-change="handleImgCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[4, 6, 8, 10]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="imgTotal"
      >
      </el-pagination>
    </el-dialog>
    <!--学生视频异常列表-->
    <el-drawer
      title="异常视频"
      :visible.sync="drawerShow"
      direction="rtl"
      :modal="false"
      :wrapperClosable="false"
      :before-close="handleDrawClose"
    >
      <div class="drawer-list">
        <div class="drawer-list-item">
          <div class="text">isejfiaviwsvws</div>
          <el-button type="text">查看</el-button>
        </div>
        <div class="drawer-list-item">
          <div class="text">isejfiaviwsvws</div>
          <el-button type="text">查看</el-button>
        </div>
        <div class="drawer-list-item">
          <div class="text">isejfiaviwsvws</div>
          <el-button type="text">查看</el-button>
        </div>
        <div class="drawer-list-item">
          <div class="text">isejfiaviwsvws</div>
          <el-button type="text">查看</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 补时Dialog -->
    <el-dialog
      title="补时时间"
      :visible.sync="timeDialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="timeForm"
        :rules="timeRules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名">
          <el-input v-model="timeForm.name" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="准考证号码">
          <el-input v-model="timeForm.zkzNums" :disabled="true"> </el-input>
        </el-form-item>

        <el-form-item label="补时码" prop="code">
          <el-input
            type="password"
            v-model="timeForm.code"
            placeholder="请输入补时码"
          ></el-input>
        </el-form-item>
        <el-form-item label="补时时长" prop="titleSubjectName">
          <el-input
            type="number"
            v-model="timeForm.titleSubjectName"
            placeholder="请输入补时时长（按分钟计算）"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideAddTime()">取 消</el-button>
        <el-button type="primary" @click="handleAddTime()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 标记异常 -->
    <el-dialog
      title="标记异常"
      :visible.sync="errorDialogVisible"
      width="40%"
      :before-close="errorhandleClose"
    >
      <el-form
        :model="errorForm"
        :rules="errorRules"
        ref="errorFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="errorForm.name" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="准考证号码" prop="zkzNums">
          <el-input v-model="errorForm.zkzNums" :disabled="true"> </el-input>
        </el-form-item>

        <el-form-item label="事件类型" prop="errorType">
          <el-select
            v-model="errorForm.errorType"
            placeholder="请选择事件类型"
            @visible-change="getErrorOption($event, 1)"
          >
            <el-option
              v-for="item in errorOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件描述" prop="errorDescribe">
          <el-input
            type="textarea"
            v-model="errorForm.errorDescribe"
            placeholder="请输入事件描述"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="errorhandleClose">取 消</el-button>
        <el-button type="primary" @click="handleError()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 请求协助 -->
    <el-dialog
      title="请求协助"
      :visible.sync="helpDialogVisible"
      width="40%"
      :before-close="helphandleClose"
    >
      <el-form
        :model="helpForm"
        :rules="helpRules"
        ref="helpFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="helpForm.name" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="准考证号码" prop="zkzNums">
          <el-input v-model="helpForm.zkzNums" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="事件类型" prop="helpType">
          <el-select
            v-model="helpForm.helpType"
            placeholder="请选择事件类型"
            @visible-change="getErrorOption($event, 0)"
          >
            <el-option
              v-for="item in helpOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件描述" prop="helpDescribe">
          <el-input
            type="textarea"
            v-model="helpForm.helpDescribe"
            placeholder="请输入事件描述"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="helphandleClose">取 消</el-button>
        <el-button type="primary" @click="handleHelp()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import Header from "c/index/Header";
import msgDialog from "c/index/examination/msgDialog";
import imgListDialog from "c/index/examination/imgListDialog";
import photoListDialog from "c/index/examination/photoListDialog";
// import {getStudentLists} from "r/index/examstudentinfolist.js";
// import {
//     getStudentsAlic,
//     listChatRecord,
//     signMessageReadable,
//     removeUser,
// } from "r/index/examstudentinfolist";
// import {openOrCloseZbByTeacher} from '../../../request/index/videoList.js'
// import {getStudentsAlic} from "r/index/examstudentinfolist";
import { videoMixin } from "mixins/index/video";
import { startOrCloseVideo } from "r/admin/examination";
import { warnImages } from "r/index/videoList";
// 异常处理
import { saveErrorEvent, getErrorEvent, getCategory } from "r/index/error";
import { formatTime } from "common/utils";

// 数据监控
import {
  getDataMonitor,
  updateTestStatus,
  updateStudent,
  AddTime,
  countJtType,
  changeStatus,
  selectSubject,
} from "r/index/videoList";

import router from "../../../router";

export default {
  name: "VideoList",
  mixins: [videoMixin],
  components: {
    Header,
    msgDialog,
    imgListDialog,
    photoListDialog,
  },
  data() {
    return {
      dialogVisibleError: false, //单个预警
      /* 补时 */
      timeDialogVisible: false, //补时
      timeForm: {
        name: "",
        zkzNums: "",

        titleSubjectName: "", //补时（时间）
      },
      timeRules: {
        code: [
          {
            required: true,
            message: "请输入补时码",
            trigger: "blur",
          },
        ],
        titleSubjectName: [
          {
            required: true,
            message: "请输入补时时长（按分钟计算）",
            trigger: "blur",
          },
        ],
      },
      id: "", //补时间参数
      /* 标记异常 */
      errorDialogVisible: false,
      errorForm: {
        name: "",
        zkzNums: "",
        errorType: "",
        errorDescribe: "",
      },
      errorRules: {
        errorType: [
          {
            required: true,
            message: "请选择事件类型",
            trigger: "blur",
          },
        ],
      },
      errorOptions: [],
      //请求协助
      helpDialogVisible: false,
      helpForm: {
        name: "",
        zkzNums: "",
        helpType: "",
        helpDescribe: "",
      },
      helpRules: {
        helpType: [
          {
            required: true,
            message: "请选择事件类型",
            trigger: "blur",
          },
        ],
      },
      helpOptions: [],

      examName: "",
      subjectName: "",
      loading: true,
      teacherId: "",
      subjectId: "",
      subjectUuid: "",
      userInfo: window.localStorage.getItem("index-userInfo"),
      dialogTableVisible: false,
      dialogVisibleImg: false,
      dialogVisiblePhoto: false,
      imagesList: [],
      errorImagesList: [],
      valuePicker: [], // 起止时间-时间选择器绑定值
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        teacherId: null,
        subjectUuid: null,
        zkzNum: null,
        startTime: null,
        finalEndTime: null,
        type: null,
      },
      queryParamsError: {
        pageNum: 1,
        pageSize: 10,
        teacherId: null,
        subjectUuid: null,
        zkzNum: null,
        startTime: null,
        finalEndTime: null,
        type: null,
      },
      imgTotal: 0,
      errorimgTotal: 0,
      // 预警类型列表
      typeList: [
        {
          value: 0,
          label: "没有人脸",
        },
        {
          value: 1,
          label: "人脸多于一个",
        },
        {
          value: 2,
          label: "戴耳机",
        },
        {
          value: 3,
          label: "打电话",
        },
      ],
      imgTimer: null, // 查看预警截图-定时器
      imgErrorTimer: null, // 查看预警截图-定时器
      fit: "cover",
      // (查看图片上传的传参)
      zkzNums: "", //准考证号
      names: "", //
      subjectUuids: "",
      // 数据监控
      showControl: 0,
      selectSubjectData: [], //分科目选择
      EnterNum: "", //进入监控人数
      notEnterNum: "", //未进入监控人数
      dataControlList: [],
      totalNum: 0,
      is_check: 0,
      queryParamsData: {
        pageNum: 1,
        pageSize: 10,
        subjectUuid: null,
        smallSubjectUuid: null,
        teacherId: null,
        condition: null,
        monitorStatus: null,
        startExamStatus: null,
        testStatus: null,
        pictureStatus: null,
        handPaperStatus: null,
        lackExamStatus: null,
        finalEndTime: null,
        faceStatus: null,
      },
      timer: null, //定时器名称
      missExamText: "", //标记缺考提示
      countJtTypesList: [], // 左侧监控列表-预警列表
      loadingJtType: false, // true-预警加载中
      noJtType: false, // 当前预数据状态 true-无数据
      noJtTypeUnCount: 0, // 当前预警人数
      JtTypeTimer: null, // 定时器刷新左侧监控列表-预警列表

      drawerShow: false, // 控制学生视频异常列表的显示与隐藏
      smallSubjectUuid: "", //select分科目
      selectSubjectLIst: [], //获取分科目List

      // 异常记录按钮
      // 异常记录tabs
      activeName: "first",

      checkErrorOpen: "",
      isPagePublisher: 1,
      dialogVisiblecheckError: false,
      checkerr: 0,

      errorLoading: false,
      ErrorEventList: [], //记录异常List
      ErrorEventTotal: 0,
      ErrorEventPageSize: 10,
      ErrorEventPageNum: 1,
      HelpEventList: [], //请求协助List
      HelpEventTotal: 0,
      HelpEventPageSize: 10,
      HelpEventPageNum: 1,
    };
  },
  created() {
    this.subjectName = this.$route.query.subjectName;
    this.subjectId = this.$route.query.subjectId;
    this.examName = this.$route.query.examName;
    this.teacherId = localStorage.getItem("index-teacherId");
    this.examUuid = this.$route.query.examUuid;
    this.subjectUuid = this.$route.query.subjectUuid;
    this.isLoading();
    // this.JtTypeTimer && clearInterval(this.JtTypeTimer);
    // this.countJtTypeList();
    // this.JtTypeTimer = setInterval(() => {
    //     this.countJtTypeList();
    // }, 600000);
    this.getSelectSubject(); //获取分科目
  },
  methods: {
    // 打开异常draw
    handleAbnormal() {
      this.drawerShow = true;
    },
    // 关闭异常draw
    handleDrawClose() {
      this.drawerShow = false;
    },
    noJtTypeButton(val) {
      if (val == 3) {
        // 预警按钮
        this.JtTypeTimer && clearInterval(this.JtTypeTimer);
        this.countJtTypeList();
        this.JtTypeTimer = setInterval(() => {
          this.countJtTypeList();
        }, 60000);
      }
    },
    // 标记查看预警
    changeStatus(zkz) {
      let obj = {
        zkzNum: zkz,
        subjectUuid: this.subjectUuid,
      };
      changeStatus(obj)
        .then((res) => {
          let resData = res.data;
          if (resData.code != 200) {
            return this.$message.error(resData.message);
          }
          this.countJtTypesList.map((item) => {
            if (item.zkzNum == zkz && item.status == 1) {
              item["status"] = 0;
              this.noJtTypeUnCount--;
            }
          });
          // this.toErrorList(zkz);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 预警状态
    countJtTypeStatus(val) {
      if (val == 0) {
        return "未检测到人脸";
      } else if (val == 1) {
        return "人脸多于1个";
      } else if (val == 2) {
        return "带耳机";
      } else if (val == 3) {
        return "打电话";
      }
    },
    // 预警列表
    countJtTypeList() {
      this.loadingJtType = true;
      this.noJtType = false;
      let obj = {
        teacherId: this.teacherId,
        subjectUuid: this.subjectUuid,
      };
      countJtType(obj)
        .then((res) => {
          let resData = res.data;
          if (resData.code != 200) {
            this.loadingJtType = false;
            this.noJtType = true;
            return this.$message.error(resData.message);
          }
          this.loadingJtType = false;
          this.countJtTypesList = resData.data;
          if (this.countJtTypesList.length <= 0) {
            this.noJtType = true;
          } else {
            this.noJtType = false;
            let noJtTypeUnCount = 0;
            this.countJtTypesList.map((item) => {
              if (item.status == 1) {
                noJtTypeUnCount++;
              }
              if (item.type) {
                item["type"] = item.type.split(",");
              } else {
                item["type"] = [];
              }
            });
            this.noJtTypeUnCount = noJtTypeUnCount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 数据监控  */
    // 搜索考生姓名
    getStaffPageLists() {
      this.topLoading = true;

      this.getDataMonitor(1);
    },
    // 获取小科目List
    getSelectSubject() {
      this.queryParamsData.subjectUuid = this.subjectUuid;
      selectSubject(this.queryParamsData).then((res) => {
        if (res.data.code != 200) {
          return this.$message.error(res.data.message);
        }
        this.selectSubjectLIst = res.data.data;
        if (window.localStorage.getItem("smallSubjectUuid")) {
          this.smallSubjectUuid =
            window.localStorage.getItem("smallSubjectUuid");
        } else {
          this.smallSubjectUuid = this.selectSubjectLIst[0].smallSubjectUuid;
          window.localStorage.setItem(
            "smallSubjectUuid",
            this.smallSubjectUuid
          );
        }
      });
    },
    changeSmallSubject(value) {
      window.localStorage.setItem("smallSubjectUuid", value);
      this.smallSubjectUuid = value;
      this.getDataMonitor();
    },
    // 获取数据监控List
    getDataMonitor(type) {
      this.timer && clearTimeout(this.timer);
      this.topLoading = true;
      this.queryParamsData.subjectUuid = this.subjectUuid;
      this.queryParamsData.teacherId = this.teacherId;
      this.queryParamsData.smallSubjectUuid =
        window.localStorage.getItem("smallSubjectUuid");
      if (type == 1) {
        this.queryParamsData.pageNum = 1;
      }
      // this.queryParamsData.condition = this.smallSubjectUuid;
      getDataMonitor(this.queryParamsData).then((res) => {
        if (res.data.code != 200) {
          return this.$message.error(res.data.message);
        }
        this.EnterNum = res.data.data.EnterNum;
        this.notEnterNum = res.data.data.notEnterNum;
        this.dataControlList = res.data.data.detail.records;
        this.totalNum = res.data.data.detail.total;
        this.topLoading = false;
        this.startTimer();
      });
    },
    handleControl() {
      // console.log(this.subjectUuid, "this.subjectUuid ");
      this.showControl = 1;
      this.isNowPage = false;
      this.changeStudentVideoList(false);
      this.getDataMonitor();
    },
    // 定时器（刷新
    startTimer() {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getDataMonitor();
      }, 60000);
    },

    handleVideo() {
      this.isNowPage = true;
      this.showControl = 0;
      this.canSetTimeoutPage = true;
      this.setTimeoutPage();
      this.changeStudentVideoList(true);
      // 消除定时刷新
      this.timer && clearTimeout(this.timer);
    },

    // 筛选
    filterChange(filters) {
      if (filters.monitorStatus != undefined) {
        this.queryParamsData.monitorStatus = filters.monitorStatus[0];
      } else if (filters.startExamStatus != undefined) {
        this.queryParamsData.startExamStatus = filters.startExamStatus[0];
      } else if (filters.testStatus != undefined) {
        this.queryParamsData.testStatus = filters.testStatus[0];
      } else if (filters.pictureStatus != undefined) {
        this.queryParamsData.pictureStatus = filters.pictureStatus[0];
      } else if (filters.handPaperStatus != undefined) {
        this.queryParamsData.handPaperStatus = filters.handPaperStatus[0];
      } else if (filters.lackExamStatus != undefined) {
        this.queryParamsData.lackExamStatus = filters.lackExamStatus[0];
      } else if (filters.faceStatus != undefined) {
        this.queryParamsData.faceStatus = filters.faceStatus[0];
      }
      this.getDataMonitor(1);
    },

    handleDataSize(val) {
      this.queryParamsData.pageSize = val;
      this.getDataMonitor();
    },
    handleDataCurrente(val) {
      this.queryParamsData.pageNum = val;
      this.getDataMonitor();
    },

    // 标记缺考
    signMissexam(row) {
      console.log(row);
      if (row.lackExamStatus == 0) {
        this.missExamText = "是否确认该考生缺考?";
      } else {
        this.missExamText = "是否取消该考生缺考?";
      }
      const h = this.$createElement;
      this.$confirm("提示", {
        message: h("div", null, this.missExamText),
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {};
          obj.id = row.id;
          if (row.lackExamStatus == 1) {
            obj.lackExamStatus = 0;
          } else {
            obj.lackExamStatus = 1;
          }
          updateStudent(obj).then((res) => {
            if (res.data.code != 200) {
              return this.$message.error(res.data.message);
            }
            this.getDataMonitor();
            this.$message.success(res.data.message);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消操作",
          });
        });
    },

    // 补时
    addTimeDialog(row) {
      // console.log(row);
      this.timeDialogVisible = true;
      this.id = row.id;
      this.timeForm.zkzNums = row.zkzNum;
      this.timeForm.name = row.name;
    },
    handleAddTime() {
      let obj = {};
      obj.id = this.id;
      obj.code = this.timeForm.code;
      obj.additionalTime = this.timeForm.titleSubjectName;

      AddTime(obj).then((res) => {
        if (res.data.code != 200) {
          return this.$message.error(res.data.message);
        }
        this.timeForm = {};
        this.$message.success("补时完成");
        this.timeDialogVisible = false;
        this.getDataMonitor();
      });
    },
    hideAddTime() {
      this.timeForm = {};
      this.timeDialogVisible = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.timeForm = {};
          this.timeDialogVisible = false;
          done();
        })
        .catch((_) => {});
    },
    /* 异常记录  */
    createTimeFormatter(row, column, cellValue, index) {
      // console.log(row);
      const time = row.createTime;
      return formatTime(time);
    },
    handleRecordError() {
      this.isNowPage = false;
      this.showControl = 2;
      this.changeStudentVideoList(false);
      this.handleErrorEventList();
    },
    // tabs
    handleClick(tab, event) {
      if (tab.paneName === "first") {
        this.handleErrorEventList();
      } else {
        this.handleHelpEventList();
      }
    },
    handleErrorEventSize(val) {
      this.ErrorEventPageSize = val;
      this.errorLoading = true;
      this.handleErrorEventList();
    },
    handleErrorEventCurrent(val) {
      this.ErrorEventPageNum = val;
      this.errorLoading = true;
      this.handleErrorEventList();
    },
    //记录异常List
    handleErrorEventList() {
      let obj = {};
      obj.type = 1;
      obj.teacherId = this.teacherId;
      obj.pageNum = this.ErrorEventPageNum;
      obj.pageSize = this.ErrorEventPageSize;
      getErrorEvent(obj).then((res) => {
        if (res.data.code != 200) {
          return this.$message.error(res.data.message);
        }
        this.ErrorEventList = res.data.data.records;
        this.ErrorEventTotal = res.data.data.total;
        this.errorLoading = false;
      });
    },
    handleHelpEventSize(val) {
      this.HelpEventPageSize = val;
      this.errorLoading = true;
      this.handleHelpEventList();
    },
    handleHelpEventCurrent(val) {
      this.HelpEventPageNum = val;
      this.errorLoading = true;
      this.handleHelpEventList();
    },
    //请求协助List
    handleHelpEventList() {
      let obj = {};
      obj.type = 0;
      obj.teacherId = this.teacherId;
      obj.pageNum = this.HelpEventPageNum;
      obj.pageSize = this.HelpEventPageSize;
      getErrorEvent(obj).then((res) => {
        if (res.data.code != 200) {
          return this.$message.error(res.data.message);
        }
        this.HelpEventList = res.data.data.records;
        this.HelpEventTotal = res.data.data.total;
        this.errorLoading = false;
      });
    },
    // 记录异常
    errorDialog(row) {
      this.errorDialogVisible = true;
      this.errorForm.zkzNums = row.zkzNum;
      this.errorForm.name = row.name;
    },
    getErrorOption(event, type) {
      if (event) {
        this.getCategoryList(type);
      }
    },
    getCategoryList(type) {
      getCategory({ type: type }).then((res) => {
        // 请求协助
        if (type == 0) {
          this.helpOptions = res.data.data;
        } else {
          this.errorOptions = res.data.data;
        }
      });
    },
    handleError() {
      this.$refs["errorFormRef"].validate((valid) => {
        if (!valid) return;
        let obj = {};
        obj.zkzNum = this.errorForm.zkzNums;
        obj.teacherId = this.teacherId;
        obj.subjectUuid = this.subjectUuid;
        obj.eventCategoryId = this.errorForm.errorType;
        obj.eventProblemDescribe = this.errorForm.errorDescribe;
        saveErrorEvent(obj).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.message);
          }
          this.$message.success(res.data.message);
          this.errorhandleClose();
          // this.getDataMonitor();
          // this.startTimer();
        });
      });
    },
    errorhandleClose() {
      this.$refs["errorFormRef"].resetFields();
      this.errorDialogVisible = false;
      // this.$confirm("确认关闭？")
      //     .then((_) => {
      //         done();
      //     })
      //     .catch((_) => {
      //     });
    },
    // 请求协助
    helpDialog(row) {
      this.helpDialogVisible = true;
      this.helpForm.zkzNums = row.zkzNum;
      this.helpForm.name = row.name;
    },
    handleHelp() {
      this.$refs["helpFormRef"].validate((valid) => {
        if (!valid) return;
        let obj = {};
        obj.zkzNum = this.helpForm.zkzNums;
        obj.teacherId = this.teacherId;
        obj.subjectUuid = this.subjectUuid;
        obj.eventCategoryId = this.helpForm.helpType;
        obj.eventProblemDescribe = this.helpForm.helpDescribe;
        saveErrorEvent(obj).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.message);
          }
          this.$message.success(res.data.message);
          this.helphandleClose();
          this.timer && clearTimeout(this.timer);
        });
      });
    },
    helphandleClose() {
      this.$refs["helpFormRef"].resetFields();
      this.helpDialogVisible = false;
      // this.$confirm("确认关闭？")
      //     .then((_) => {
      //         done();
      //     })
      //     .catch((_) => {
      //     });
    },
    /* 查看单个预警截图 */
    // 打开预警截图弹框
    toErrorList(zkzNum) {
      this.dialogVisibleError = true;
      this.zkzNums = zkzNum;
    },
    // 查看预警截图-获取数据
    async openErroImgDialog() {
      window.clearInterval(this.imgErrorTimer);
      this.imgErrorTimer = window.setInterval(() => {
        this.openErroImgDialog();
      }, 2000);
      this.queryParamsError.teacherId = this.teacherId;
      this.queryParamsError.subjectUuid = this.subjectUuid;
      this.queryParamsError.zkzNum = this.zkzNums;
      // console.log(this.queryParamsError);
      const { data: res } = await warnImages(this.queryParamsError);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.errorImagesList = res.data.records;
      this.errorimgTotal = res.data.total;
    },

    // 关闭预警截图弹框
    closeErrorImgDialog() {
      window.clearInterval(this.imgErrorTimer);
    },

    handleErrImgSize(val) {
      this.queryParamsError.pageSize = val;
      this.openErroImgDialog();
    },
    handleErrImgCurrente(val) {
      this.queryParamsError.pageNum = val;
      this.openErroImgDialog();
    },

    /* 查看图片-按钮 */
    toImgList(item, num, name) {
      this.dialogVisibleImg = true;
      this.zkzNums = num;
      this.names = name;
      this.subjectUuids = this.subjectUuid;
    },
    closeImgListDialog(val, type) {
      this.dialogVisibleImg = val;
      if (type) {
        this.timer && clearTimeout(this.timer);
      }
    },
    /* 查看证件照-按钮 */
    toPhotoList(item, num, name) {
      this.dialogVisiblePhoto = true;
      this.zkzNums = num;
      this.names = name;
      this.subjectUuids = this.subjectUuid;
    },
    closePhotoListDialog(val) {
      this.dialogVisiblePhoto = val;
    },
    handleImgSizeChange(val) {
      this.queryParams.pageSize = val;
      this.openImgDialog();
    },
    handleImgCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.openImgDialog();
    },
    queryHandle() {
      this.queryParams.pageNum = 1;
      this.openImgDialog();
    },
    resetHandle() {
      this.valuePicker = [];
      this.queryParams.startTime = null;
      this.queryParams.finalEndTime = null;
      this.$refs["queryForm"].resetFields();
      this.queryHandle();
    },
    typeFormat(row) {
      let arr = row.type.split(",");
      let strArr = [];
      arr.forEach((item) => {
        this.typeList.forEach((item2) => {
          if (item2.value == item) {
            strArr.push(item2.label);
          }
        });
      });
      return strArr;
    },
    changePicker(val) {
      if (val && val.length > 0) {
        this.queryParams.startTime = val[0];
        this.queryParams.finalEndTime = val[1];
      } else {
        this.queryParams.startTime = null;
        this.queryParams.finalEndTime = null;
      }
    },
    // 关闭预警截图弹框
    closeImgDialog() {
      window.clearInterval(this.imgTimer);
    },
    // 查看预警截图-获取数据
    async openImgDialog() {
      window.clearInterval(this.imgTimer);
      this.imgTimer = window.setInterval(() => {
        this.openImgDialog();
      }, 2000);
      this.queryParams.teacherId = this.teacherId;
      this.queryParams.subjectUuid = this.subjectUuid;
      const { data: res } = await warnImages(this.queryParams);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.imagesList = res.data.records;
      this.imgTotal = res.data.total;
    },
    // 开启或者关闭截图
    async startOrCloseVideoByTeacher(status, message) {
      const confirmResult = await this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了操作");

      const { data: res } = await startOrCloseVideo({
        teacherId: this.teacherId,
        subjectUuid: this.subjectUuid,
        status,
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
    isLoading() {
      setTimeout(() => {
        if (
          this.subjectName != "" &&
          this.examName != "" &&
          this.teacherId != ""
        ) {
          this.loading = false;
        }
      }, 300);
    },
    openWarnJt() {
      this.dialogTableVisible = true;
    },
    // 给填码通道和胶卷通道赋值
    showChannel(item) {
      console.log(item);
      this.radioCode = item.codeStatus;
      this.radioPaper = item.handPaperStatus;
      this.radioTest = item.startExamStatus;
    },
  },
  // 销毁监听事件
  destroyed() {
    this.timer && clearTimeout(this.timer);
    this.JtTypeTimer && clearInterval(this.JtTypeTimer);
    localStorage.removeItem("smallSubjectUuid");
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/table";
@import "a/scss/page";
@import "a/scss/indexList";
@import "a/scss/btn";

.flex {
  display: flex;
}

.el-button + .el-button {
  margin-left: 0px;
}

.el-button {
  border-color: #fff !important;
}

::v-deep .el-table__body-wrapper {
  // height: 100% !important;
  height: 700px !important;
}

.row_bg {
  padding: 24px 24px 0 24px;

  .control_num {
    font-size: 15px;

    & span {
      margin-right: 20px;
    }
  }
}

.table-box {
  flex: 1;
  overflow: scroll;
}

.open-drawer {
  position: absolute;
  left: 150px;
  bottom: 20px;
  transform: translate(-50%, 0);
  color: #ffffff;
  background-color: #563279;
  font-size: 16px;
  padding: 5px 15px;
  cursor: pointer;

  .el-icon-s-unfold {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    vertical-align: middle;
  }
}

.monitor_box_drawer {
  width: 300px !important;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  box-shadow: 1px 1px 50px 0 rgba(0, 0, 0, 0.3);

  ::v-deep .el-drawer {
    width: 100% !important;
    padding: 20px 10px;

    .el-drawer__body {
      .el-icon-d-arrow-left {
        color: #563279;
        font-size: 40px;
        font-weight: bold;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
      }
    }
  }
}

.monitor_box-container {
  width: 100%;
  height: 100%;

  .monitor_area {
    margin: 0 10px 15px 0;
    // 老师摄像头
    .monitor_box {
      position: relative;
      z-index: 2001;
      border-radius: 6px;
      width: 256px;
      height: 192px;
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px dotted #ccc;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 5px;
      }

      .video_action {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;

        .ydfont,
        .el-icon-chat-dot-round,
        .el-icon-top,
        .el-icon-bottom {
          cursor: pointer;
          padding: 2px;
          margin-right: 2px;
          display: inline-block;
          width: 28px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #ffffff;
          border-radius: 50%;
          color: #563279;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .video-empty {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .video_loading {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.05);
        color: #52a1e4;
      }
    }
    .monitor_area_box {
      margin-top: 4px;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      .monitor_area_name {
        font-size: 16px;
        line-height: 28px;
      }
      .monitor_area_btn {
        margin-right: 10px;
        background-color: #563279;
        color: #ffffff;
      }
    }
  }
}

.horizontal_video {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

// logo
.thussat_logo {
  width: 20%;
  position: absolute;
  z-index: 99;
}

.isFacePass {
  position: absolute;
  z-index: 8;
  background-color: red;
  color: #ffff;
  padding: 5px;
  bottom: 0;
  right: 0;
  font-size: 10px;
  border-radius: 0 0 6px 0;
}

.status_icon i {
  right: 2%;
  bottom: 2%;
  position: absolute;
  z-index: 999;
}

.el-container,
.checkerror .el-dialog__body {
  .el-main {
    padding: 20px 10px 0 10px;
    // overflow: hidden;
    .el-container {
      width: 100%;
      margin-top: 15px;
      display: flex;
      flex-flow: row;

      .el-aside {
        width: 300px;
        height: 100%;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        background-color: #fff;
        border-radius: 6px;

        .message-box {
          p {
            text-align: center;
            line-height: 30px;
            height: 30px;
            color: #aaa;
          }
        }

        ::v-deep .jtType-box {
          .el-menu-item {
            height: 80px !important;

            .el-link {
              color: #62affe !important;
            }

            .count {
              color: #f56c6c;
            }

            .el-col-8 > div {
              width: 100%;
              padding: 0 20px;
              text-align: right;
            }
          }
        }

        .aside-header {
          width: 100%;
          // height: 12.5%;
          text-align: center;
          padding: 0 5%;

          > h2 {
            // height: 50%;
            padding: 5% 0;
            // height: 55px;
            // line-height: 55px;
            text-align: center;
            font-size: 18px;
            font-family: "Source Han Sans CN";
            font-weight: 300;
            color: #333333;
          }

          > div {
            height: 40px;

            ::v-deep .el-input {
              position: relative;
              display: block;

              .el-input__inner {
                height: 40px;
                line-height: 40px;
                color: #cccccc;
                background-color: #fafafa;
                border-radius: 6px;
                border: none;
                font-size: 12px;
                font-family: "Source Han Sans CN";
                font-weight: 300;
              }
            }
          }

          clear: both;
        }

        .menu-box {
          flex: 1;
          overflow: auto;
          border-top: 1px solid #f5f5f5;
          margin-top: 6%;
          padding: 6% 0 0 0;
          display: flex;
          flex-flow: column;

          ::v-deep .el-radio-group {
            height: 6%;
            line-height: 6%;
            display: flex;
            flex-flow: row;
            /*margin-left: 50px;*/
            .el-radio-button {
              flex: 1;
            }

            .el-radio-button__inner {
              /*width: 100px;*/
              width: 100%;
              padding: 10% 0;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;

              &:hover {
                color: #56327a;
              }
            }

            .el-radio-button.is-active {
              .el-radio-button__inner {
                color: #fff;
                background-color: #56327a;
                border-color: #56327a;
              }
            }

            .el-checkbox-group {
              margin-left: 50px;
              border-top: 1px solid #f5f5f5;
              padding-top: 18px;
            }

            ::v-deep .el-badge__content.is-fixed {
              position: absolute;
              top: 12px;
              right: 36px;
              border: none;
            }
          }

          .el-menu {
            flex: 1;
            margin: 20px 0 0 0;
            overflow: auto;

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              margin: 5px;
              font-size: 12px;
              font-family: "Source Han Sans CN";
              font-weight: 300;
              color: rgba(0, 0, 0, 0.7);
              padding: 0 10px 0 20px;

              // 单行文本溢出隐藏显示省略号
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:first-child {
                margin-top: 0;
              }

              .item-badge {
                width: 100%;
              }

              .el-row {
                height: 100%;
                padding: 0;

                .el-col-8 {
                  height: inherit;

                  div {
                    float: left;
                    height: 40px;
                    line-height: 40px;
                    padding-left: 4px;
                    margin-top: -2px;

                    .el-link {
                      height: 100%;
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: normal;
                      opacity: 0.7;
                    }

                    &:first-child {
                      .el-link {
                        color: #f56c6c;

                        &.is-disabled {
                          color: #b3b3b3;
                        }
                      }
                    }

                    &:last-child {
                      .el-link {
                        color: #62affe;

                        &.is-disabled {
                          color: #b3b3b3;
                        }
                      }
                    }
                  }
                }
              }

              ::v-deep .el-badge__content.is-fixed.is-dot {
                position: absolute;
                right: 10px;
                top: 10px;
              }
            }

            .el-menu-item:hover,
            .el-menu-item:hover .el-link,
            .is-active,
            .is-active .el-link {
              background-color: #56327a;

              .el-col-16 {
                color: rgba(255, 255, 255, 0.85) !important;
              }
            }
          }
        }
      }

      .el-main {
        flex: 1;
        padding: 0 0 0 1%;
        display: flex;
        flex-flow: column;

        .video-user-box {
          flex: 1;
          overflow: auto;
          position: relative;

          .user-box {
            &.video-size1 {
              > div {
                width: 100%;
              }
            }

            &.video-size3 {
              width: 49%;

              > div {
                width: 32.3%;
              }
            }

            width: 33%;
            height: 32.8%;
            display: inline-block;
            margin: 0 0.5% 0 0;
            background: #fff;
            border-radius: 6px;
            padding: 0;

            &:nth-child(3n) {
              margin-right: 0;
            }

            > div {
              width: 49%;
              height: 80%;
              display: inline-block;
              position: relative;
              margin-right: 1%;
              border-radius: 6px;
              float: left;

              &.user-info {
                width: 100%;
                font-size: 12px;
                padding: 0 10px;
                margin-right: 0;
                margin-top: 15px;
                font-family: "Source Han Sans CN";
                font-weight: 400;
                color: #333333;
                max-height: 30px;

                .user_text {
                  // width: 50%;
                  position: relative;
                  display: inline-block;

                  &:first-child {
                    /*width: 65%;*/
                    text-align: left;

                    > span:nth-child(2) {
                      margin-right: 5px;
                    }
                  }

                  &:nth-child(2) {
                    width: 35%;
                    text-align: right;

                    > div {
                      color: #52a1e4;
                      display: inline-block;
                      cursor: pointer;
                      margin-right: 5px;
                    }

                    > span {
                      height: 10px;
                      width: 46px;
                      display: inline-block;
                      position: relative;
                    }

                    > .text_btn_view {
                      width: 90px;
                    }

                    > .text_btn_view2 {
                      width: 110px;
                    }
                  }
                }
              }

              .text_btn2 {
                color: #52a1e4;
                // height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;
              }

              &.showVideo {
                > video {
                  /*border: 1px dotted #ccc;*/
                }
                ::v-deep .el-loading-mask {
                  border: 1px dotted #ccc;
                  border-radius: 6px;
                }
              }

              > video {
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 6px;
                position: absolute;
                top: 0;
                z-index: 2;
                left: 0;
              }

              .video_action {
                position: absolute;
                top: 8px;
                right: 2px;
                z-index: 3;

                .ydfont,
                .el-icon-chat-dot-round,
                .el-icon-top,
                .el-icon-bottom {
                  padding: 2px;
                  margin-right: 2px;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  text-align: center;
                  background: #ffffff;
                  border-radius: 50%;
                  color: #563279;
                  font-size: 20px;
                  font-weight: bold;
                }
              }

              .video_time {
                position: absolute;
                bottom: 8px;
                left: 6px;
                z-index: 3;
                color: #d81e06;
                font-size: 16px;
                /*background-color: rgba(225, 225, 225, 0.7);*/

                .el-icon-time {
                  font-size: 18px;
                }
              }

              .video_sign {
                position: absolute;
                top: 8px;
                left: 6px;
                z-index: 3;
                color: #ffffff;
                font-size: 12px;
                background-color: #c280ff;
                padding: 5px 10px;
                border-radius: 4px;
              }

              .video_loading {
                position: absolute !important;
                top: 1px;
                height: 98%;
                z-index: 1;
                width: 98%;
                left: 1px;
                background-color: #ccc;
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
            }
          }

          .user-boxerror {
            &.video-size1 {
              > div {
                width: 100%;
              }
            }

            position: absolute;
            width: 45%;
            height: 650px;
            top: 0;
            z-index: 100;
            display: inline-block;
            background: #fff;
            border-radius: 6px;
            padding: 40px 20px 0 20px;
            left: 20%;

            &:nth-child(2n) {
              margin-right: 0;
              margin-left: 0.5%;
            }

            > div {
              &.user-check_close {
                width: 28px;
                height: 28px;
                position: absolute;
                right: -3px;
                top: 0;
                color: #56327a;
                font-size: 28px;
                z-index: 9;
                cursor: pointer;
              }

              width: 49%;
              height: 80%;
              display: inline-block;
              position: relative;
              margin-right: 1%;
              border-radius: 6px;
              float: left;

              &.user-info {
                width: 100%;
                font-size: 12px;
                padding: 0 10px;
                margin-right: 0;
                margin-top: 15px;
                font-family: "Source Han Sans CN";
                font-weight: 400;
                color: #333333;
                max-height: 30px;

                .user_text {
                  width: 50%;
                  position: relative;
                  display: inline-block;

                  &:first-child {
                    text-align: left;
                  }

                  &:nth-child(2) {
                    text-align: right;

                    > div {
                      color: #52a1e4;
                      display: inline-block;
                      cursor: pointer;
                      margin-right: 5px;
                    }

                    > span {
                      height: 10px;
                      width: 40px;
                      display: inline-block;
                      position: relative;
                    }
                  }
                }
              }

              .text_btn2 {
                color: #52a1e4;
                // height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;
              }

              &.showVideo {
                > video {
                  border: 1px dotted #ccc;
                }
              }

              > video {
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 6px;
                position: absolute;
                top: 0;
                z-index: 2;
                left: 0;
              }

              .video_action {
                position: absolute;
                top: 8px;
                right: 2px;
                z-index: 3;

                .ydfont,
                .el-icon-chat-dot-round,
                .el-icon-top,
                .el-icon-bottom {
                  padding: 2px;
                  margin-right: 2px;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  text-align: center;
                  background: #ffffff;
                  border-radius: 50%;
                  color: #563279;
                  font-size: 20px;
                  font-weight: bold;
                }
              }

              .video_loading {
                position: absolute !important;
                top: 1px;
                height: 98%;
                z-index: 1;
                width: 98%;
                left: 1px;
                background-color: #ccc;
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
            }
          }
        }

        .el-pagination {
          padding: 1.5% 10px;
          margin-top: 0;
        }
      }

      .menu-box {
        .no-data {
          font-size: 18px;
        }
      }

      .no-data {
        text-align: center;
        font-size: 28px;
        color: #aaa;
        line-height: 400px;
      }
    }
  }

  .el-select {
    width: 100%;
  }
}

.el-dialog__footer {
  .dialog-footer {
    .el-button:nth-child(1) {
      background-color: #ffffff;
      border: 1px solid #56327a !important;
      color: #56327a;
      margin-right: 10px;
    }

    .el-button:nth-child(2) {
      background-color: #56327a;
      border: 1px solid #56327a !important;
      color: #ffffff;
    }
  }
}

.video-user-dialog {
  flex: 1;
  overflow: auto;
  position: absolute;
  width: 40%;
  min-width: 620px;
  /*height: 650px;*/
  min-height: 520px;
  left: 30%;

  .user-box {
    &.video-size1 {
      > div {
        width: 100%;
      }
    }

    width: 33%;
    height: 32.8%;
    display: inline-block;
    margin: 0 0.5% 0 0;
    background: #fff;
    border-radius: 6px;
    padding: 0;

    &:nth-child(3n) {
      margin-right: 0;
    }

    > div {
      width: 49%;
      height: 80%;
      display: inline-block;
      position: relative;
      margin-right: 1%;
      border-radius: 6px;
      float: left;

      &.user-info {
        width: 100%;
        font-size: 12px;
        padding: 0 10px;
        margin-right: 0;
        margin-top: 15px;
        font-family: "Source Han Sans CN";
        font-weight: 400;
        color: #333333;
        max-height: 30px;

        .user_text {
          width: 50%;
          position: relative;
          display: inline-block;

          &:first-child {
            text-align: left;

            > span:nth-child(2) {
              margin-right: 5px;
            }
          }

          &:nth-child(2) {
            text-align: right;

            > div {
              color: #52a1e4;
              display: inline-block;
              cursor: pointer;
              margin-right: 5px;
            }

            > span {
              height: 10px;
              width: 46px;
              display: inline-block;
              position: relative;
            }
          }
        }
      }

      .text_btn2 {
        color: #52a1e4;
        // height: 20px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
      }

      &.showVideo {
        > video {
          border: 1px dotted #ccc;
        }
      }

      > video {
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 6px;
        position: absolute;
        top: 0;
        z-index: 2;
        left: 0;
      }

      .video_action {
        position: absolute;
        top: 8px;
        right: 2px;
        z-index: 3;

        .ydfont,
        .el-icon-chat-dot-round,
        .el-icon-top,
        .el-icon-bottom {
          padding: 2px;
          margin-right: 2px;
          display: inline-block;
          width: 28px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #ffffff;
          border-radius: 50%;
          color: #563279;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .video_loading {
        position: absolute !important;
        top: 1px;
        height: 98%;
        z-index: 1;
        width: 98%;
        left: 1px;
        background-color: #ccc;
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }

  .user-boxerror {
    &.video-size {
      > div {
        width: 100%;
      }
    }

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 100;
    display: inline-block;
    background: #fff;
    border-radius: 6px;
    padding: 40px 20px 0 20px;

    &:nth-child(2n) {
      margin-right: 0;
      margin-left: 0.5%;
    }

    > div {
      &.user-check_close {
        width: 28px;
        height: 28px;
        position: absolute;
        right: -3px;
        top: 0;
        color: #56327a;
        font-size: 28px;
        z-index: 9;
        cursor: pointer;
      }

      width: 32%;
      height: 80%;
      display: inline-block;
      position: relative;
      margin-right: 1%;
      border-radius: 6px;
      float: left;

      &.user-info {
        width: 100%;
        font-size: 12px;
        padding: 0 10px;
        margin-right: 0;
        margin-top: 15px;
        font-family: "Source Han Sans CN";
        font-weight: 400;
        color: #333333;
        max-height: 30px;

        .user_text {
          width: 50%;
          position: relative;
          display: inline-block;

          &:first-child {
            text-align: left;
          }

          &:nth-child(2) {
            text-align: right;

            > div {
              color: #52a1e4;
              display: inline-block;
              cursor: pointer;
              margin-right: 5px;
            }

            > span {
              height: 10px;
              width: 45px;
              display: inline-block;
              position: relative;
            }
          }
        }
      }

      .text_btn2 {
        color: #52a1e4;
        // height: 20px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
      }

      &.showVideo {
        > video {
          border: 1px dotted #ccc;
        }
      }

      > video {
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 6px;
        position: absolute;
        top: 0;
        z-index: 2;
        left: 0;
      }

      .video_action {
        position: absolute;
        top: 8px;
        right: 2px;
        z-index: 3;

        .ydfont,
        .el-icon-chat-dot-round,
        .el-icon-top,
        .el-icon-bottom {
          padding: 2px;
          margin-right: 2px;
          display: inline-block;
          width: 28px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #ffffff;
          border-radius: 50%;
          color: #563279;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .video_loading {
        position: absolute !important;
        top: 1px;
        height: 98%;
        z-index: 1;
        width: 98%;
        left: 1px;
        background-color: #ccc;
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }
}

::v-deep.checkerror .el-loading-mask {
  z-index: 100 !important;
  // background-color: ;
}

.top_video {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;

  > video {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 6px;
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
  }

  .video_action {
    position: absolute;
    top: 8px;
    right: 2px;
    z-index: 3;

    .ydfont {
      padding: 2px;
      margin-right: 2px;
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #ffffff;
      border-radius: 50%;
      color: #563279;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

#btnJt {
  height: 100%;
}

.jt {
  height: 100%;
  margin: 0 5px;
  padding-top: 10px;
}

@-webkit-keyframes myAnimation {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}

@keyframes myAnimation {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}

.abnormalList {
  ::v-deep .is-dot {
    -webkit-animation: myAnimation 1s infinite;
    animation: myAnimation 1s infinite;
  }
}

.drawer-list {
  padding: 20px;

  .drawer-list-item {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .text {
      line-height: 30px;
    }

    .el-button {
      height: 30px;
      line-height: 30px;
      padding: 0;
      background-color: transparent;
      text-decoration: underline;
    }
  }
}

.checkBg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);
}

.popover-tree {
  height: 250px;
  display: flex;
  flex-flow: column;

  ::v-deep .el-tree {
    flex: 1;
    overflow: scroll;

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #563279;
      border-color: #563279;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner,
    .el-checkbox__input:hover .el-checkbox__inner {
      border-color: #563279;
    }

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;

      .status_1 {
        color: #563279;
        font-size: 12px;
      }
    }
  }

  .tree-btn {
    padding: 10px 0 0;
    text-align: center;

    .el-button {
      height: 28px;
      padding: 0 15px;
      font-size: 12px;
      color: #563279;
      background-color: #ffffff;
      border: 1px solid #563279 !important;

      &.confirm {
        color: #ffffff;
        background-color: #563279;
        margin-left: 20px;
      }
    }
  }
}
</style>
