import {request} from "./network";

// 开启或者关闭该监考老师下的监考学生
export function openOrCloseZbByTeacher(params) {
    return request({
        method: "get",
        url: "/video/openOrCloseZbByTeacher",
        params,
    });
}

// 查看预警截图
export function warnImages(params) {
    return request({
        method: "get",
        url: "/video/warnImages",
        params,
    });
}

// 查看上传图片
export function getStudentUrl(params) {
    return request({
        method: "get",
        url: "/student/getStudentUrl",
        params,
    });
}

// 消息


// 数据监控
// export function getDataMonitor(params) {
//     return request({
//         method: "get",
//         url: "/system/getMonitorTeacherDetail",
//         params,
//     });
// }

// 数据监控（美院）
export function getDataMonitor(params) {
    return request({
        method: "get",
        url: "/system/getMonitorTeacherDetailMeiYuan",
        params,
    });
}

// 修改缺考（美院）
export function updateStudent(params) {
    return request({
        method: "get",
        url: "/system/updateStudentMonitorMeiYuan",
        params,
    });
}

// 补时（美院）
export function AddTime(params) {
    return request({
        method: "get",
        url: "/system/updateAdditionalMeiYuan",
        params,
    });
}

export function updateTestStatus(params) {
    return request({
        method: "get",
        url: "/system/updateTestStatus",
        params,
    });
}

// 预警考生列表
export function countJtType(params) {
    return request({
        method: "get",
        url: "/video/countJtType",
        params,
    });
}

// 预警标记查看
export function changeStatus(params) {
    return request({
        method: "get",
        url: "/video/changeStatus",
        params,
    });
}


//数据监控（科目分小类）
export function selectSubject(params) {
    return request({
        method: "get",
        url: "/system/selectBySubjectUuid",
        params,
    });
}

//指定终端用户从频道踢出
export function removeUser(examUuid, subjectUuId, channelId, user_id) {
    return request({
        method: "get",
        url: "removeTerminals/" + examUuid + '/' + subjectUuId + '/' + channelId + "?terminalIds=" + user_id,
    });
}

// 查询监控路数的变化
export function monitorNumListener(params) {
    return request({
        method: "get",
        url: "system/recordMonitorNum",
        params
    });
}

// 记录填码和交卷通道
export function recordChannel(params) {
    return request({
        method: "get",
        url: "system/handPaperChannel",
        params
    });
}

//add 20220507 baiansen
//老师通话 通知录制
export function startRecord(teacherId,channelId) {
    return request({
        method: "get",
        url: "startRecord/"+teacherId +"/"+channelId,
    });
}
//老师关闭通话 通知录制关闭
export function stopRecord(teacherId,channelId) {
    return request({
        method: "get",
        url: "stopRecord/"+teacherId +"/"+channelId,
    });
}

//老师关闭通话 通知录制关闭
export function environmentPhotos(query) {
    return request({
        method: "get",
        url: "/student/environmentPhotos",
        params: query,
    });
}

