<template>
    <el-dialog
            :visible.sync="dialogVisible"
            title="查看证件照"
            center
            width="600px"
            @open="openDialog"
            :before-close="cancelBtn"
            :close-on-click-modal="false"
    >
        <div class="imgList">
            <div class="img_title">{{ name }}-{{ zkzNum }}</div>
            <div v-if="imgUrl.length > 0">
                <div class="ever_List">
                    <div v-for="(item, index) in imgUrl" :key="index"
                         class="flex each_subject"
                    >
                        <el-image
                                :src="item"
                                :preview-src-list="[item]"
                        >
                        </el-image>
                    </div>
                </div>
            </div>
            <div class="block" v-else>无图片</div>
        </div>
    </el-dialog>
</template>
<script>
    import {environmentPhotos} from "r/index/videoList";

    export default {
        name: "photoListDialog",
        props: {
            dialogVisibleFu: Boolean,
            zkzNums: String,
            names: String,
            subjectUuids: String,
        },
        data() {
            return {
                dialogVisible: this.dialogVisibleFu, // 控制dialog弹框显示与隐藏
                zkzNum: this.zkzNums,
                name: this.names,
                subjectUuid: this.subjectUuids,
                imgUrl: [],
            };
        },
        methods: {
            openDialog() {
                environmentPhotos({
                    zkzNum: this.zkzNum,
                    subjectUuid: this.subjectUuid,
                }).then((res) => {
                    console.log(res)
                    if (res.data.code != 200) {
                        return this.$message.error(res.data.message);
                    }
                    if(res.data.data.picZhengjian){
                        let arr = res.data.data.picZhengjian.split(',');
                        this.imgUrl = arr;
                    }
                });
            },
            cancelBtn() {
                this.dialogVisible = false;
                this.imgUrl = [];
                this.$emit("closeDialog", this.dialogVisible);
            },
        },
        watch: {
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            },
            zkzNums() {
                this.zkzNum = this.zkzNums;
            },
            names() {
                this.name = this.names;
            },
            subjectUuids() {
                this.subjectUuid = this.subjectUuids;
            },
        },
    };
</script>
<style scoped lang="scss">
    .flex {
        display: flex;
    }

    .imgList {
        .img_title {
            margin: 10px;
            font-size: 18px;
        }

        span {
            font-size: 16px;
            margin: 10px;
        }

        .ever_List {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            div {
                flex-direction: column;
                width: 25%;
            }

            .each_subject {
                max-width: 100%;
                min-width: 25%;
                padding: 10px 0;
                >.el-image{
                    width: 100px;
                    height: 100px;
                }
            }
        }

        img {
            margin: 10px;
            width: 100px;
        }
    }
</style>

