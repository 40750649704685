import {
    requestError
} from "./network";

//监考段获取异常列表
export function getErrorEvent(params) {
    return requestError({
        method: "get",
        url: "eventProblem/getEventByTeacher",
        params,
    });
}

//保存待处理问题
export function saveErrorEvent(params) {
    return requestError({
        method: "post",
        url: "eventProblem/saveEventProblem",
        params,
    });
}


export function getCategory(params) {
    return requestError({
        method: "get",
        url: "eventProblem/getCategory",
        params,
    });
}


