<template>
  <!-- dialog弹框 -->
  <el-dialog
    :visible.sync="dialogVisible"
    title="查看上传图片"
    center
    width="600px"
    @open="openDialog"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="imgList">
      <div class="img_title">{{ name }}-{{ zkzNum }}</div>
      <div v-if="imgUrl.length > 0">
        <div v-for="(item, index) in imgUrl" :key="index">
          <span>{{ item.name }}</span>
          <div class="ever_List">
            <div
              v-for="(item2, key) in item.value"
              :key="key"
              class="flex each_subject"
            >
              <!-- <span class="pic_id"> 第 {{ key }} 题</span> -->
              <el-image
                style="width: 100px; height: 100px"
                :src="item2"
                :preview-src-list="[item.value[key]]"
              >
              </el-image>
            </div>
          </div>
        </div>
        <!-- <div>试卷袋图片</div>
        <el-image
          style="width: 100px; height: 100px"
          :src="paperBag[0]"
          :preview-src-list="paperBag"
        >
        </el-image> -->
      </div>
      <div class="block" v-else>无图片</div>
    </div>
  </el-dialog>
</template>
<script>
import { getStudentUrl } from "r/index/videoList";

export default {
  name: "imgListDialog",
  props: {
    dialogVisibleImg: Boolean,
    zkzNums: String,
    names: String,
    subjectUuids: String,
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleImg, // 控制dialog弹框显示与隐藏
      zkzNum: this.zkzNums,
      name: this.names,
      subjectUuid: this.subjectUuids,
      imgUrl: [],
      paperBag: [], //试卷袋图片
    };
  },
  methods: {
    openDialog() {
      getStudentUrl({
        zkzNum: this.zkzNum,
        subjectUuid: this.subjectUuid,
      }).then((res) => {
        console.log(res, "getStudentUrl");
        if (res.data.code != 200) {
          return this.$message.error(res.data.message);
        }
        if (res.data.data.studentAnswer != "") {
          // 考生上传图片
          if (res.data.data.studentAnswer != []) {
            let arr = JSON.parse(res.data.data.studentAnswer);
            arr.map((item) => {
              return item;
            });
            this.imgUrl = arr;
          }

          if (res.data.data.studentPaperBag != "") {
            let arr2 = res.data.data.studentPaperBag.split(" ");
            arr2.map((item) => {
              return item;
            });
            this.paperBag = arr2;
          }
        }
      });
    },
    cancelBtn() {
      this.dialogVisible = false;
      this.imgUrl = [];
      this.$emit("closeImgListDialog", this.dialogVisible, false);
    },
  },
  watch: {
    dialogVisibleImg() {
      this.dialogVisible = this.dialogVisibleImg;
    },
    zkzNums() {
      this.zkzNum = this.zkzNums;
    },
    names() {
      this.name = this.names;
    },
    subjectUuids() {
      this.subjectUuid = this.subjectUuids;
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}

.imgList {
  .img_title {
    margin: 10px;
    font-size: 18px;
  }

  span {
    font-size: 16px;
    margin: 10px;
  }

  .ever_List {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div {
      flex-direction: column;
      min-width: 25%;
    }

    .each_subject {
      // flex-direction: column;
      // width: 25%;
      max-width: 100%;
      min-width: 25%;
      padding: 10px 0;
    }
  }

  img {
    margin: 10px;
    width: 100px;
  }
}
</style>
